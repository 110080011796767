import React, { useState } from "react";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

import axios from "axios";
import useSWR from "swr";

import EditCompleted from "./editCompleted";

const fetcher = (url) => axios.get(url).then((res) => res.data);

const OrdersCompleted = (props) => {
  const [editNotes, setEditNotes] = useState(null);

  function handleUpdate() {
    setEditNotes(false)
    mutate();
  }

  function Orders({ data }) {
    return (
      <>
        <h4>Completed Orders</h4>

        <Table hover responsive size="sm">
          <thead className="table-light">
            <tr>
              <th>Order</th>
              <th>Owner</th>
              <th>Site</th>
              <th>Date Ordered</th>
              <th>Date Picked</th>
              <th>Ordered By</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index + 1} style={{ verticalAlign: "middle" }}>
                <td>
                  <Link to={`/order/${item.OrderNum}?type=3`}>{item.OrderNum} </Link>
                </td>
                <td>{item.Owner}</td>
                <td>{item.SiteName}</td>
                <td>{item.DateOrdered}</td>
                <td>{item.DatePicked}</td>
                <td>{item.OrderedBy}</td>
                <td>
                  {item.notes ? (
                    <span
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "blue",
                        display: "inline-block",
                      }}
                      onClick={() => setEditNotes({ orderNum: item.OrderNum, notes: item.notes })}
                    >
                      {item.notes}
                    </span>
                  ) : (
                    <Button onClick={() => setEditNotes({ orderNum: item.OrderNum, notes: item.notes })}>Edit</Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {editNotes?.orderNum != null && <EditCompleted item={editNotes} onHide={() => setEditNotes(null)} onUpdate={handleUpdate}/>}
      </>
    );
  }

  const { data, error, mutate } = useSWR(`/api/orders/completed?customer=${props.customer}`, fetcher);
  if (error) return "An error has occurred.";
  return <>{data && data.length > 0 && <Orders data={data} />}</>;
};

export default OrdersCompleted;
