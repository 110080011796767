import React, { useRef, useEffect, useState } from "react";
import axios from "axios";

import { Form, Row, Button, Modal } from "react-bootstrap";

const EditCompleted = ({ item, onHide, onUpdate }) => {
  const notesRef = useRef();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (item.orderNum) setVisible(true);
    else setVisible(false);
  }, [item]);

  const saveHandler = () => {
    // console.log("saveHandler", notesRef.current.value, item.notes, item.orderNum);
    if (notesRef.current.value === item.notes) {
      return onHide();
    }
    axios
      .post("/api/orders/updateCompletedNotes", {
        orderNum: item.orderNum,
        notes: notesRef.current.value,
      })
      .then(function (response) {
        onUpdate();
      })
      .catch(function (error) {
        onUpdate();
        console.error("/api/orders/updateCompletedNotes", error);
      });
  };

  return (
    <Modal show={visible} onHide={onHide} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Notes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={Row} className="mb-2 mx-2">
          <Form.Control autoFocus type="text" defaultValue={item.notes} maxLength={15} ref={notesRef}></Form.Control>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={onHide}>
          Cancel
        </Button>
        <Button onClick={saveHandler}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditCompleted;
