import React, { useRef } from "react";

import { Form, Row, Col, Button, Modal } from "react-bootstrap";

let whRow = ["", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
let whRack = ["", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20"];
let whShelf = ["", "A", "B", "C", "D", "E", "F", "G", "H"];

const EditBatchItem = (props) => {
  const partNumRef = useRef();
  const serialNumRef = useRef();
  const aluPONumRef = useRef();
  const poNumRef = useRef();
  const soNumRef = useRef();
  const descriptionRef = useRef();
  const rowRef = useRef();
  const rackRef = useRef();
  const shelfRef = useRef();
  const quantityRef = useRef();

  const Rows = () => (
    <Form.Select
      className="mt-1"
      defaultValue={props.row}
      ref={rowRef}

      //   onChange={(e) => (selectedRow = e.target.value)}
    >
      {whRow.map((item, index) => (
        <option key={index} value={item}>
          {item}{" "}
        </option>
      ))}
    </Form.Select>
  );

  const Racks = () => (
    <Form.Select className="mt-1" defaultValue={props.rack} ref={rackRef}>
      {whRack.map((item, index) => (
        <option key={index} value={item}>
          {item}
        </option>
      ))}
    </Form.Select>
  );

  const Shelfs = () => (
    <Form.Select className="mt-1" defaultValue={props.shelf} ref={shelfRef}>
      {whShelf.map((item, index) => (
        <option key={index} value={item}>
          {item}
        </option>
      ))}
    </Form.Select>
  );

  const saveHandler = () => {
    const savedData = {
      partNum: partNumRef.current.value,
      serialNum: serialNumRef.current.value,
      aluPONum: aluPONumRef.current.value,
      poNum: poNumRef.current.value,
      soNum: soNumRef.current.value,
      description: descriptionRef.current.value,
      row: rowRef.current.value,
      rack: rackRef.current.value,
      shelf: shelfRef.current.value,
      quantity: quantityRef.current.value,
    };
    props.onUpdate(savedData);
    props.onHide();
  };

  return (
    <Modal show={props.showModal} onHide={props.onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Edit Part</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-2">
          <Form.Group as={Col}>
            <Form.Label>Part #</Form.Label>
            <Form.Control type="text" defaultValue={props.partNum} ref={partNumRef} />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>{props.customer === "41" ? "Material ID" : "Serial #"}</Form.Label>
            <Form.Control type="text" defaultValue={props.serialNum} ref={serialNumRef} />
          </Form.Group>
        </Row>
        <Row className="mb-2">
          <Form.Group as={Col}>
            <Form.Label>{props.customer === "34" ? "ALU #" : "Vendor"}</Form.Label>
            <Form.Control type="text" defaultValue={props.aluPONum} ref={aluPONumRef} />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>PO #</Form.Label>
            <Form.Control type="text" defaultValue={props.poNum} ref={poNumRef} />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>{props.customer === "41" ? "SO # / Bin #" : "SO #"}</Form.Label>
            <Form.Control type="text" defaultValue={props.soNum} ref={soNumRef} />
          </Form.Group>
        </Row>
        <Form.Group className="mb-2">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            // as="textarea"
            // rows={2}
            defaultValue={props.description}
            placeholder="Description"
            ref={descriptionRef}
          />
        </Form.Group>
        <Form.Group as={Row} className="mb-2">
          <Col>
            Row
            <Rows />
          </Col>
          <Col>
            Rack
            <Racks />
          </Col>
          <Col>
            Shelf
            <Shelfs />
          </Col>
        </Form.Group>
        <Row>
          <Form.Group as={Col} sm={2}>
            <Form.Label>Quantity</Form.Label>
            <Form.Control type="number" defaultValue={props.quantity} placeholder="0" ref={quantityRef} />
          </Form.Group>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={saveHandler}>Save Changes </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditBatchItem;
