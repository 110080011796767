import React, { useState, useRef } from "react";
import axios from "axios";

import { Form, Row, Col, Button, Alert, Table, Container } from "react-bootstrap";

import OwnerSelect from "../../components/ownerSelect";
import DeleteConfirmation from "../../components/delete";

import authHeader from "../../auth-header";

const InventoryInput = (props) => {
  const [owner, setOwner] = useState("");
  const [description, setDescription] = useState("");
  const [addedParts, setAddedParts] = useState([]);
  const [inputState, setInputState] = useState(0);
  const [duplicatePart, setDuplicatePart] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(-1);

  const partNumRef = useRef();
  const serialNumRef = useRef();
  const aluPONumRef = useRef();
  const poNumRef = useRef();
  const soNumRef = useRef();
  const descriptionRef = useRef();
  const quantityRef = useRef();
  const notesRef = useRef();

  const ResetForm = () => {
    partNumRef.current.value = "";
    serialNumRef.current.value = "";
    setInputState(0);
    partNumRef.current.focus();
  };

  const searchPart = () => {
    axios
      .get("/api/inventory/searchPart", {
        params: {
          partNum: partNumRef.current.value,
        },
        headers: authHeader(),
      })
      .then((res) => {
        const data = res.data;
        if (data.length > 0) {
          setDescription(data[0].Description);
          setDuplicatePart(true);
        } else {
          setDescription("");
          setDuplicatePart(false);
        }
        setInputState(2);
        aluPONumRef.current.focus();
        // }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const searchHandler = (e) => {
    e.preventDefault();
    searchPart();
    // console.log("searchHandler");
  };

  const addInventory = () => {
    axios
      .post(
        "/api/inventory/add",
        {
          owner: owner,
          description: descriptionRef.current.value,
          partNum: partNumRef.current.value,
          aluPONum: aluPONumRef.current.value,
          poNum: poNumRef.current.value,
          soNum: soNumRef.current.value,
          quantity: quantityRef.current.value,
          serialNum: serialNumRef.current.value,
          // checkedBy: props.employeeId,
          warehouse: "Doughton",
          notes: notesRef.current.value,
        },
        { headers: authHeader() }
      )
      .then(function (response) {
        setAddedParts((prevParts) => {
          const updatedParts = [...prevParts];
          updatedParts.unshift(response.data);
          return [...updatedParts];
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addHandler = (e) => {
    e.preventDefault();
    addInventory();
    setInputState(0);
  };

  const deleteDataHandler = () => {
    // console.log("DeleteHandler");
    setAddedParts((prevBatch) => {
      const updatedBatch = prevBatch.filter((item, index) => index !== selectedItem);
      return updatedBatch;
    });
    setDeleteModalShow(false);
  };

  const AddedParts = () => {
    return (
      <>
        <DeleteConfirmation showModal={deleteModalShow} onDelete={deleteDataHandler} onHide={() => setDeleteModalShow(false)} />
        <Container fluid>
          <Alert variant="success">Successfully Added:</Alert>

          <Table responsive>
            <thead className="table-light">
              <tr>
                <th>SKU</th>
                <th>Owner</th>
                <th>Part #</th>
                <th>{props.customer === "41" ? "Material ID" : "Serial #"}</th>
                <th>ALU #</th>
                <th>PO #</th>
                <th>{props.customer === "41" ? "SO # / Bin #" : "SO #"}</th>
                <th>Description</th>
                <th>Stock</th>
              </tr>
            </thead>
            <tbody>
              {addedParts.map((item, index) => (
                <tr key={index + 1}>
                  <td>{item.SKUNum} </td>
                  <td>{item.Owner} </td>
                  <td>{item.PartNum}</td>
                  <td>{item.SerialNum}</td>
                  <td>{item.ALUPONum}</td>
                  <td>{item.PONum}</td>
                  <td>{item.SONum}</td>
                  <td>{item.Description}</td>
                  <td>{item.Quantity}</td>
                  {/* <td>
                    <i
                      className="bi-pencil-square"
                      style={{
                        cursor: "pointer",
                        marginRight: "20px",
                        color: "blue",
                      }}
                      // onClick={() => EditPart(item,index)}
                    />
                    <i
                      className="bi-trash"
                      style={{
                        cursor: "pointer",
                        color: "red",
                      }}
                      onClick={() => deletePart(index)}
                    />
                  </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>
      </>
    );
  };

  const InputItem = () => {
    return (
      <Container fluid>
        <Form onSubmit={addHandler}>
          <Row className="mb-2">
            <Form.Group as={Col} lg={2} md={4}>
              <Form.Label>{props.customer === "34" ? "ALU PO Number" : "Vendor"}</Form.Label>
              <Form.Control required type="text" autoFocus ref={aluPONumRef} />
            </Form.Group>
            <Form.Group as={Col} lg={2} md={4}>
              <Form.Label>Customer PO Number</Form.Label>
              <Form.Control required type="text" ref={poNumRef} />
            </Form.Group>
            <Form.Group as={Col} lg={2} md={4}>
              <Form.Label>{props.customer === "41" ? "SO # / Bin #" : "SO #"}</Form.Label>
              <Form.Control required type="text" ref={soNumRef} />
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} lg={5} md={8}>
              <Form.Label>Description</Form.Label>
              <Form.Control type="text" required defaultValue={description} disabled={duplicatePart} ref={descriptionRef} />
            </Form.Group>
            <Form.Group as={Col} lg={1} md={2}>
              <Form.Label>Quantity</Form.Label>
              <Form.Control type="number" defaultValue="0" ref={quantityRef} />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} lg={6} md={8}>
              <Form.Label>Notes</Form.Label>
              <Form.Control type="text" ref={notesRef} />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Col>
              <Button variant="primary" type="submit" className="me-5">
                Add
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    );
  };

  return (
    <>
      <Container fluid>
        {/* <h4 style={{ backgroundColor: "#F0F0F0" }}>Input</h4> */}

        <Form onSubmit={searchHandler}>
          <Row className="mb-3">
            <Form.Group as={Col} xl={2} lg={3} md={4}>
              <Form.Label>Owner</Form.Label>
              <OwnerSelect setOwnerHandler={setOwner} customer={props.customer} />
            </Form.Group>
            <Form.Group as={Col} xl={2} lg={3} md={4}>
              <Form.Label>Part Number</Form.Label>
              <Form.Control required type="text" ref={partNumRef} />
            </Form.Group>
            <Form.Group as={Col} xl={2} lg={3} md={4}>
              <Form.Label>{props.customer === "41" ? "Material ID" : "Serial #"}</Form.Label>
              <Form.Control type="text" ref={serialNumRef} maxLength={32} />
            </Form.Group>
            <Col className="align-self-end">
              <Button variant="primary" type="submit" className="me-5">
                Search
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
      {inputState === 2 && <InputItem />}
      {addedParts.length > 0 && <AddedParts />}
    </>
  );
};

export default InventoryInput;
