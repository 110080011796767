import React, { useState } from "react";
import axios from "axios";
import { Form, Row, Col, Container, Button, InputGroup, FormControl, Alert, Table, OverlayTrigger, Tooltip } from "react-bootstrap";

import DeleteConfirmation from "../../components/delete";
import OwnerSelect from "../../components/ownerSelect";
import EditBatchItem from "./editBatch";

import authHeader from "../../auth-header";

const InventoryBatch = (props) => {
  const [owner, setOwner] = useState("");
  const [selectedFile, setSelectedFile] = useState([null]);
  const [batch, setBatch] = useState([]);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(-1);
  const [editItem, setEditItem] = useState(-1);
  const [addedParts, setAddedParts] = useState([]);

  // function uploadFile(selectedFile) {
  function uploadFile() {
    const formData = new FormData();
    formData.append("file", selectedFile);

    // console.log(selectedFile)

    axios
      .post("/api/inventory/uploadCSV", formData, { headers: authHeader() })
      .then(function (response) {
        setBatch(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    // fetch("/api/inventory/uploadCSV", {
    //   // content-type header should not be specified!
    //   method: "POST",
    //   body: formData,
    // })
    //   .then((response) => response.json())
    //   .then((success) => {
    //     // Do something with the successful response
    //     //console.log(success);
    //     setBatch(success);
    //   })
    //   .catch((error) => console.log(error));
  }

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const batchHandler = (e) => {
    e.preventDefault();
    if (selectedFile != null) {
      // console.log("batchHandler");
      uploadFile();
      setAddedParts([]);
    }
  };

  const deletePart = (i) => {
    setSelectedItem(i);
    setDeleteModalShow(true);
  };

  const deleteDataHandler = () => {
    // console.log("DeleteHandler");
    setBatch((prevBatch) => {
      const updatedBatch = prevBatch.filter((item, index) => index !== selectedItem);
      return updatedBatch;
    });
    setDeleteModalShow(false);
  };

  const updateDataHandler = (savedData) => {
    setBatch(
      batch.map((item, index) =>
        index === selectedItem
          ? {
              ...item,
              partNum: savedData.partNum,
              serialNum: savedData.serialNum,
              aluPONum: savedData.aluPONum,
              poNum: savedData.poNum,
              soNum: savedData.soNum,
              description: savedData.description,
              quantity: savedData.quantity,
              row: savedData.row,
              rack: savedData.rack,
              shelf: savedData.shelf,
              Edited: true,
            }
          : item
      )
    );
  };

  const editPart = (item, i) => {
    setSelectedItem(i);
    setEditItem(item);
    setEditModalShow(true);
  };

  const addInventory = async () => {
    // update SKU
    setBatch(
      batch.map((item) => {
        item.owner = owner;
        return item;
      })
    );

    axios
      .post("/api/inventory/add2", batch, { headers: authHeader() })
      .then(function (response) {
        setAddedParts(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addParts = () => {
    // console.log("addParts "+JSON.stringify(batch));
    // return;
    addInventory();
    setBatch([]);
  };

  const UploadedBatch = () => {
    return (
      <>
        <DeleteConfirmation showModal={deleteModalShow} onDelete={deleteDataHandler} onHide={() => setDeleteModalShow(false)} />
        <EditBatchItem
          partNum={editItem.partNum}
          serialNum={editItem.serialNum}
          aluPONum={editItem.aluPONum}
          poNum={editItem.poNum}
          soNum={editItem.soNum}
          description={editItem.description}
          row={editItem.row}
          rack={editItem.rack}
          shelf={editItem.shelf}
          quantity={editItem.quantity}
          showModal={editModalShow}
          onUpdate={updateDataHandler}
          onHide={() => setEditModalShow(false)}
        />
        <Container fluid onSubmit={addParts}>
          <Alert variant="info">Successfully Imported:</Alert>

          <Table responsive hover>
            <thead>
              <tr>
                <th>Part #</th>
                <th>{props.customer === "41" ? "Material ID" : "Serial #"}</th>
                <th>{props.customer === "34" ? "ALU PO #" : "Vendor"}</th>
                <th>PO #</th>
                <th>{props.customer === "41" ? "SO # / Bin #" : "SO #"}</th>
                <th>Description</th>
                <th>Row</th>
                <th>Rack</th>
                <th>Shelf</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {batch.map((item, index) => {
                // const editField = (value, index) => {
                //   console.log('editing index '+index+' value '+value)
                // };
                return (
                  <tr key={index + 1} style={item.Edited ? { background: "lightyellow" } : {}}>
                    <td>{item.partNum} </td>
                    {/* <td>
                      <input
                        value={item[0]}
                        onChange={(e) => editField(e.target.value, 0)}
                      ></input>
                    </td> */}
                    <td>{item.serialNum} </td>
                    <td>{item.aluPONum} </td>
                    <td>{item.poNum} </td>
                    <td>{item.soNum} </td>
                    <td>{item.description} </td>
                    <td>{item.row}</td>
                    <td>{item.rack}</td>
                    <td>{item.shelf}</td>
                    <td>{item.quantity} </td>
                    <td>
                      <OverlayTrigger overlay={<Tooltip>Edit</Tooltip>}>
                        <i
                          className="bi-pencil-square"
                          style={{
                            cursor: "pointer",
                            marginRight: "20px",
                            color: "blue",
                          }}
                          onClick={() => editPart(item, index)}
                        />
                      </OverlayTrigger>
                      <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                        <i
                          className="bi-trash"
                          style={{
                            cursor: "pointer",
                            color: "red",
                          }}
                          onClick={() => deletePart(index)}
                        />
                      </OverlayTrigger>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Button onClick={addParts}>Add Parts</Button>
        </Container>
      </>
    );
  };

  const AddedParts = () => {
    return (
      <>
        <Container fluid>
          <Alert variant="success">Successfully Added:</Alert>

          <Table responsive>
            <thead>
              <tr>
                <th>SKU</th>
                <th>Owner</th>
                <th>Part #</th>
                <th>{props.customer === "41" ? "Material ID" : "Serial #"}</th>
                <th>{props.customer === "34" ? "ALU #" : "Vendor"}</th>
                <th>PO #</th>
                <th>{props.customer === "41" ? "SO # / Bin #" : "SO #"}</th>
                <td>Description</td>
                <th>Row</th>
                <th>Rack</th>
                <th>Shelf</th>
                <th>Stock</th>
              </tr>
            </thead>
            <tbody>
              {addedParts.map((item, index) => (
                <tr key={index + 1}>
                  <td>{item.SKUNum} </td>
                  <td>{item.Owner} </td>
                  <td>{item.PartNum}</td>
                  <td>{item.SerialNum}</td>
                  <td>{item.ALUPONum}</td>
                  <td>{item.PONum}</td>
                  <td>{item.SONum}</td>
                  <td>{item.Description}</td>
                  <td>{item.Row}</td>
                  <td>{item.Rack}</td>
                  <td>{item.Shelf}</td>
                  <td>{item.Quantity}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>
      </>
    );
  };

  return (
    <>
      {/* {deleteModalShow && (
        <DeleteConfirmation
          onDelete={deleteDataHandler}
          onHide={() => setDeleteModalShow(false)}
        />
      )} */}
      <Container fluid>
        <Form onSubmit={batchHandler}>
          <Row className="mb-3">
            <Form.Group as={Col} lg={3} md={4}>
              <Form.Label>Owner</Form.Label>
              <OwnerSelect setOwnerHandler={setOwner} customer={props.customer} />
            </Form.Group>
            <Form.Group as={Col} sm={5} className="align-self-end">
              {/* <Form.Label>File to Upload</Form.Label> */}
              <InputGroup>
                <FormControl type="file" onChange={handleFileInput} />
                <Button type="submit">Import</Button>
              </InputGroup>
            </Form.Group>
          </Row>
        </Form>
      </Container>
      {batch.length > 0 && <UploadedBatch />}
      {addedParts.length > 0 && <AddedParts />}
    </>
  );
};

export default InventoryBatch;
