import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Image from "react-bootstrap/Image";

import Logo from "../../images/ExcelLogo.png";

import { Button, Card, Container, Row, Form, Alert } from "react-bootstrap";

export default function LoginForm({ setToken }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [showError, setShowError] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === true) {
      axios
        .post("/api/users/login", {
          username: username,
          password: password,
        })
        .then(function (response) {
          if (response.data.token) {
            setToken(response.data);
            //            localStorage.setItem('user',JSON.stringify(response.data))
          }
          // props.setRealName(response.data.realname);
        })
        .catch(function (error) {
          //          console.log(error);
          setShowError(true);
        });
    } else {
      setValidated(true);
    }
  };

  return (
    <Container className="h-100">
      <Row className="justify-content-sm-center">
        <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-9">
          <Card className="mt-5 shadow bg-light">
            <Card.Body className="p-5">
              <Card.Title className="text-center mb-3">Welcome to</Card.Title>
              <div className="text-center mb-5">
                <Image src={Logo} style={{ width: "200px" }} />
                {/* 
                <img
                  src="https://www.excel-group.com/wp-content/uploads/2017/08/eXcel_logo.png"
                  alt="logo"
                  width="120"
                /> */}
              </div>

              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-2 text-muted">Username</Form.Label>
                  <Form.Control type="text" className="form-control" required autoFocus onChange={(e) => setUserName(e.target.value)} onFocus={() => setShowError(false)} />
                  <Form.Control.Feedback type="invalid">Please enter your username.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-2 text-muted">Password</Form.Label>
                  <Form.Control type="password" required onChange={(e) => setPassword(e.target.value)} onFocus={() => setShowError(false)} />
                  <Form.Control.Feedback type="invalid">Please enter your password.</Form.Control.Feedback>
                </Form.Group>
                <Button type="submit" className="d-flex ms-auto mt-4">
                  Login
                </Button>
              </Form>
              {showError && (
                <Alert variant="danger" className="mt-4 text-center">
                  Invalid Login. Please try again.
                </Alert>
              )}
            </Card.Body>
          </Card>
          <div className="text-center mt-5 text-muted">Copyright &copy; 2022 &mdash; Excel Group</div>
          <div />
        </div>
      </Row>
    </Container>
  );
}

LoginForm.propTypes = {
  setToken: PropTypes.func.isRequired,
};
