import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Alert from "react-bootstrap/Alert";
import FormControl from "react-bootstrap/FormControl";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import InputGroup from "react-bootstrap/InputGroup";

import DeleteConfirmation from "../../components/delete";
import ConfirmRequest from "../../components/confirm";
import ShippingAddress from "./shipping";
import Notes from "../../components/notes";
import OwnerSelect from "../../components/ownerSelect";

import authHeader from "../../auth-header";

let ordered = [];

const CreateOrder = (props) => {
  let navigate = useNavigate();
  const [existingOrder, setExistingOrder] = useState(false);
  const [searchState, setSearchState] = useState(0);
  const [parts, setParts] = useState([]);
  const [heldParts, setHeldParts] = useState([]);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(-1);
  const [showShipTo, setShowShipTo] = useState(false);
  const [showShipFrom, setShowShipFrom] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [notes, setNotes] = useState();
  const [shipping, setShipping] = useState();
  const [shipFrom, setShipFrom] = useState();
  const [owner, setOwner] = useState();

  // const ownerRef = useRef("");
  const siteRef = useRef();
  const requestedForRef = useRef();

  const [searchType, setSearchType] = useState(0);
  const searchRef = useRef();

  const [searchItems, setSearchItems] = useState([
    { title: "All", text: "All Fields", field: "" },
    { title: "Part Number", text: "Part Number", field: "PartNum" },
    { title: "ALU PO Numberrr", text: "ALU PO Number", field: "ALUPONum" },
    { title: "Customer PO Number", text: "Customer PO Number", field: "PONum" },
    { title: "SO Number", text: "SO Number", field: "SONum" },
  ]);

  useEffect(() => {
    if (props.customer === "34") {
      setSearchItems([
        { title: "All", text: "All Fields", field: "" },
        { title: "Part Number", text: "Part Number", field: "PartNum" },
        { title: "ALU PO Numberrr", text: "ALU PO Number", field: "ALUPONum" },
        {
          title: "Customer PO Number",
          text: "Customer PO Number",
          field: "PONum",
        },
        { title: "SO Number", text: "SO Number", field: "SONum" },
        { title: "SKU Number", text: "SKU Number", field: "SKUNum" },
        { title: "Serial #", text: "Serial #", field: "serialNum" },
      ]);
    } else if (props.customer === "41") {
      setSearchItems([
        { title: "All", text: "All Fields", field: "" },
        { title: "Part Number", text: "Part Number", field: "PartNum" },
        { title: "Vendor", text: "Vendor", field: "Vendor" },
        {
          title: "Customer PO Number",
          text: "Customer PO Number",
          field: "PONum",
        },
        { title: "SO # / Bin #", text: "SO # / Bin #", field: "SONum" },
        { title: "SKU Number", text: "SKU Number", field: "SKUNum" },
        { title: "Material ID", text: "Material ID", field: "serialNum" },
      ]);
    } else {
      setSearchItems([
        { title: "All", text: "All Fields", field: "" },
        { title: "Part Number", text: "Part Number", field: "PartNum" },
        { title: "Vendor", text: "Vendor", field: "Vendor" },
        {
          title: "Customer PO Number",
          text: "Customer PO Number",
          field: "PONum",
        },
        { title: "SO Number", text: "SO Number", field: "SONum" },
        { title: "SKU Number", text: "SKU Number", field: "SKUNum" },
        { title: "Serial #", text: "Serial #", field: "serialNum" },
      ]);
    }
  }, [props.customer]);

  // get held orders
  useEffect(() => {
    console.log("getHeldOrders", props.customer);
    axios
      .get(`/api/orders/heldParts?customer=${props.customer}`, {
        headers: authHeader(),
      })
      .then(function (response) {
        // console.log("response", response.data);
        setHeldParts(response.data);
        // if there was held orders, update search params
        if (response.data.length > 0) {
          setExistingOrder(true);
          // console.log("Pending Order");
          // ownerRef.current.value = response.data[0].Owner;
          siteRef.current.value = response.data[0].SiteName;
          requestedForRef.current.value = response.data[0].OrderedFor;
          //console.log(response);
        } else {
          // console.log("New Order");
          setExistingOrder(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [props.customer]);

  const searchParts = () => {
    // console.log("searchParts", searchRef.current.value);
    setSearchState(1);

    axios
      .get("/api/orders/createSearch", {
        params: {
          owner: owner,
          value: searchRef.current.value,
          field: searchItems[searchType].field,
        },
        headers: authHeader(),
      })
      .then(function (response) {
        if (response.data.length > 0) {
          setSearchState(0);
          setParts(response.data);
        } else {
          setSearchState(2);
          setParts([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const holdParts = (partstoHold) => {
    axios
      .post(`/api/orders/holdParts?customer=${props.customer}`, partstoHold, { headers: authHeader() })
      .then(function (response) {
        // console.log("got response");
        // console.log(response.data);
        setHeldParts((prevParts) => [...prevParts, ...response.data]);
        // update the parts
        searchParts();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const searchHandler = (e) => {
    // console.log(notes);
    e.preventDefault();
    // setOrdered([]);
    // console.log(e);
    ordered = [];
    searchParts();
    // console.log("searchHandler");
  };

  const holdPartsHandler = () => {
    setExistingOrder(false);

    // make a list of the ordered parts
    const partsToHold = parts
      .map((item, index) =>
        ordered[index] > "0" && ordered[index] <= item.Quantity // only add if quantity entered is not more that what is available
          ? // ? { ...item, Quantity: item["Quantity"] - ordered[index] }
            {
              ...item,
              Ordered: parseInt(ordered[index]),
              SiteName: siteRef.current.value,
              OrderedFor: requestedForRef.current.value,
            }
          : item
      )
      .filter((item) => item.Ordered > 0);
    //console.log(partsToAdd);

    holdParts(partsToHold);
    ordered = [];
  };

  const editOrder = (e, i) => {
    ordered[i] = e.target.value;
  };

  const deletePartHandler = () => {
    //console.log("DeletePartHandler");
    setHeldParts((prevParts) => {
      const updatedParts = prevParts.filter((item) => item.Id !== selectedItem);
      return updatedParts;
    });
    setDeleteModalShow(false);

    axios
      .post(
        "/api/orders/deleteHeldPart",
        {
          id: selectedItem,
        },
        { headers: authHeader() }
      )
      .then(function (response) {
        // update the parts
        searchParts();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deletePart = (i) => {
    //console.log("deletePart " + i);
    setSelectedItem(i);
    setDeleteModalShow(true);
  };

  const addNotes = async (orderNum) => {
    try {
      const response = await axios.post("/api/orders/addNotes", { orderNum: orderNum, notes: notes }, { headers: authHeader() });
    } catch (err) {
      console.log(err);
    }
  };

  const addShipping = async (orderNum) => {
    try {
      const response = await axios.post("/api/orders/addShipping", { orderNum: orderNum, shipping: shipping }, { headers: authHeader() });
    } catch (err) {
      console.log(err);
    }
  };

  const addShipFrom = async (orderNum) => {
    try {
      const response = await axios.post("/api/orders/addShipFrom", { orderNum: orderNum, shipFrom: shipFrom }, { headers: authHeader() });
    } catch (err) {
      console.log(err);
    }
  };

  const sendPDF = (orderNum) => {
    console.log("createPDF");
    axios
      .get(`/api/orders/createPDF?orderNum=${orderNum}`, {
        headers: authHeader(),
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const finalizeOrderHandler = () => {
    // console.log('finalizeOrderHandler')
    setHeldParts([]);

    axios
      .post("/api/orders/finalize", {}, { headers: authHeader() })
      .then(function (response) {
        const orderNum = response.data[0].OrderNum;
        // console.log(orderNum);

        // update notes
        if (notes) addNotes(orderNum);

        // update shipping
        if (shipping) addShipping(orderNum);

        // update shipping from
        if (shipFrom) addShipFrom(orderNum);

        // email order
        sendPDF(orderNum);

        // update shipping
        navigate("/");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const saveShippingHandler = (props) => {
    setShipping(props);
    setShowShipTo(false);
  };

  const saveShipFromHandler = (props) => {
    setShipFrom(props);
    setShowShipFrom(false);
  };

  const saveNotesHandler = (props) => {
    //  console.log("saveNotesHandler");
    // console.log(props);
    setNotes(props);
    setShowNotes(false);
  };

  const HeldParts = () => {
    return (
      <Container fluid>
        <ConfirmRequest showModal={confirmModalShow} message="Are you sure you want to finalize this order?" onConfirm={finalizeOrderHandler} onHide={() => setConfirmModalShow(false)} />

        <Table responsive>
          <thead className="table-light">
            <tr>
              <th>Owner</th>
              <th>Part #</th>
              <th>{props.customer === "34" ? "ALU #" : "Vendor"}</th>
              <th>PO #</th>
              <th>{props.customer === "41" ? "SO # / Bin #" : "SO #"}</th>
              <th>Description</th>
              <th>Ordered</th>
              <td colSpan={1} />
            </tr>
          </thead>
          <tbody>
            {heldParts.map((item) => {
              return (
                <tr key={item.Id}>
                  <td>{item.Owner} </td>
                  <td>{item.PartNum} </td>
                  <td>{item.ALUPONum} </td>
                  <td>{item.PONum} </td>
                  <td>{item.SONum} </td>
                  <td>{item.Description} </td>
                  <td>{item.Ordered} </td>
                  <td>
                    <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                      <i
                        className="bi-trash"
                        style={{
                          cursor: "pointer",
                          color: "red",
                        }}
                        onClick={() => deletePart(item.Id)}
                      />
                    </OverlayTrigger>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <DeleteConfirmation showModal={deleteModalShow} onDelete={deletePartHandler} onHide={() => setDeleteModalShow(false)} />
      </Container>
    );
  };

  const SearchResults = () => {
    return (
      <Container fluid>
        <Alert variant="info">
          Search Results:
          <Button variant="outline-secondary" className="float-end" onClick={holdPartsHandler}>
            Add to Order
          </Button>
        </Alert>

        <Table responsive>
          <thead className="table-light">
            <tr>
              <th>Owner</th>
              <th>Part #</th>
              <th>{props.customer === "34" ? "ALU #" : "Vendor"}</th>
              <th>PO #</th>
              <th>{props.customer === "41" ? "SO # / Bin #" : "SO #"}</th>
              <th>Description</th>
              <th>{props.customer === "41" ? "Material ID" : "Serial #"}</th>
              <th>SKU #</th>
              <th>Stock</th>
              <th>Order</th>
            </tr>
          </thead>
          <tbody>
            {parts.map((item, index) => {
              if (item.valid === false) {
                console.log(item);
                console.log(ordered[index]);
              }
              // console.log(ordered[index])
              return (
                <tr
                  // className="align-middle"
                  key={index + 1}
                  // className={item.valid === false && "table-danger"}
                >
                  <td>{item.Owner} </td>
                  <td>{item.PartNum} </td>
                  <td>{item.ALUPONum} </td>
                  <td>{item.PONum} </td>
                  <td>{item.SONum} </td>
                  <td>{item.Description} </td>
                  <td>{item.SerialNum} </td>
                  <td>{item.SKUNum} </td>
                  <td>{item.Quantity} </td>
                  <td style={{ width: "7rem" }}>
                    {typeof item.valud !== "undefined" || item.valid === false ? (
                      <FormControl type="number" style={{ backgroundColor: "red" }} defaultValue={ordered[index]} onChange={(e) => editOrder(e, index)} />
                    ) : (
                      <FormControl type="number" onChange={(e) => editOrder(e, index)} />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Container>
    );
  };

  const NoResults = () => (
    <Container fluid>
      <Alert variant="danger">That part could not be found.</Alert>
    </Container>
  );

  const IncompletOrder = () => (
    <Container fluid>
      <Alert variant="warning">You have an incomplete order.</Alert>
    </Container>
  );

  return (
    <>
      <ShippingAddress title="Ship To Address" showModal={showShipTo} onSave={saveShippingHandler} onHide={() => setShowShipTo(false)} data={shipping} />
      <ShippingAddress title="Ship From Address" showModal={showShipFrom} onSave={saveShipFromHandler} onHide={() => setShowShipFrom(false)} data={shipFrom} />
      <Notes showModal={showNotes} onHide={() => setShowNotes(false)} onSave={saveNotesHandler} notes={notes} />
      <Container fluid>
        <Form onSubmit={searchHandler}>
          <Row className="mt-2">
            <Form.Group as={Col} xl={2} lg={3} md={4}>
              <Form.Label>Owner</Form.Label>
              <OwnerSelect setOwnerHandler={setOwner} customer={props.customer} />
            </Form.Group>
            <Form.Group as={Col} xl={2} lg={3} md={4}>
              <Form.Label>Site</Form.Label>
              <Form.Control required type="text" ref={siteRef} />
            </Form.Group>
            <Form.Group as={Col} xl={2} lg={3} md={4}>
              <Form.Label>Requested For</Form.Label>
              <Form.Control required type="text" ref={requestedForRef} />
            </Form.Group>
            <Col className="align-self-end">
              <Button variant="outline-secondary" onClick={() => setShowShipTo(true)} className="me-5">
                Ship To
              </Button>
              <Button variant="outline-secondary" onClick={() => setShowShipFrom(true)} className="me-5">
                Ship From
              </Button>
              <Button variant="outline-secondary" onClick={() => setShowNotes(true)}>
                Notes
              </Button>
            </Col>
          </Row>
          <Row className="mt-2 mb-3">
            <Form.Group as={Col} xl={4} lg={5} sm={7}>
              <Form.Label>Part Search</Form.Label>

              <InputGroup>
                <DropdownButton onSelect={(e) => setSearchType(e)} variant="outline-secondary" title={searchItems[searchType].title} id="input-group-dropdown-1">
                  {searchItems.map((item, i) => (
                    <Dropdown.Item key={i} eventKey={i}>
                      {item.text}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
                <Form.Control type="text" ref={searchRef} />
                <Button type="submit">Search</Button>
              </InputGroup>
            </Form.Group>
            <Col className="align-self-end">
              <Button
                // className="me-5"
                onClick={() => setConfirmModalShow(true)}
                disabled={heldParts.length === 0}
              >
                Finalize
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
      {existingOrder && heldParts.length > 0 && <IncompletOrder />}
      {searchState === 2 && <NoResults />}
      {heldParts.length > 0 && <HeldParts />}
      {parts.length > 0 && <SearchResults />}
    </>
  );
};

export default CreateOrder;
