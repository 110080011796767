import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Navbar from "react-bootstrap/Navbar";
import Form from "react-bootstrap/Form";
import axios from "axios";

import Logo from "../../images/logo.png";
import authHeader from "../../auth-header";

const MyHeader = (props) => {
  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  const customerRef = useRef(0);

  useEffect(() => {
    axios
      .get("/api/users/customers", {
        headers: authHeader(),
      })
      .then(function (response) {
        // console.log('customers',response.data);
        setCustomers(response.data);
        if (response.data.length > 0) {
          props.updateCustomer(response.data[0].id);
        } else {
          props.updateCustomer(0);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const handleLogout = () => {
    props.doLogout();
    navigate("/");
  };

  const handleChange = () => {
    // console.log("handleChange", customerRef.current.value);
    props.updateCustomer(customerRef.current.value);
    // navigate("/");
  };

  const CustomerSelect = () => {
    return (
      <Form.Select style={{ width: "200px" }} onChange={handleChange} ref={customerRef} defaultValue={customerRef.current.value}>
        {customers.map((item, index) => (
          <option value={item.id} key={item.id}>
            {item.customer}
          </option>
        ))}
      </Form.Select>
    );
  };

  return (
    <Navbar bg="light">
      <Container fluid>
        <Image src={Logo} style={{ height: "50px" }} />
        {customers.length > 0 && <CustomerSelect />}
        <div>
          <Button type="click" variant="default" onClick={() => navigate("/settings")}>
            <i className="bi-person" style={{ marginRight: "10px" }}></i>
            {props.realName}
          </Button>
          <Button type="click" variant="outline-dark" style={{ marginLeft: "30px" }} onClick={handleLogout}>
            <i className="bi-door-open-fill" style={{ marginRight: "10px" }}></i>
            Logout
          </Button>
        </div>
      </Container>
    </Navbar>
  );
};

export default MyHeader;
