import React, { useRef, useEffect } from "react";

import { Form, Row, Col, Button, Modal } from "react-bootstrap";

let whRow = ["", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
let whRack = ["", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20"];
// let whShelf = ["", "1", "2", "3", "4", "5", "6", "7", "8"];
let whShelf = ["", "A", "B", "C", "D", "E", "F", "G", "H"];

const EditInventory = (props) => {
  const rowRef = useRef();
  const rackRef = useRef();
  const shelfRef = useRef();
  const serialNumRef = useRef();
  const vendorRef = useRef();
  const PONumRef = useRef();
  const SONumRef = useRef();
  const descriptionRef = useRef();
  const quantityRef = useRef();

  //   console.log("EditInventory");

  const modalShown = () => {
    console.log("modalShow", props);
    // vendorRef.current = props.vendor;
    // PONumRef.current = props.poNum;
    // SONumRef.current = props.soNum;
  };

  const Rows = () => (
    <Form.Select
      className="mt-1"
      defaultValue={props.row}
      ref={rowRef}

      //   onChange={(e) => (selectedRow = e.target.value)}
    >
      {whRow.map((item, index) => (
        <option key={index} value={item}>
          {item}{" "}
        </option>
      ))}
    </Form.Select>
  );

  const Racks = () => (
    <Form.Select className="mt-1" defaultValue={props.rack} ref={rackRef}>
      {whRack.map((item, index) => (
        <option key={index} value={item}>
          {item}
        </option>
      ))}
    </Form.Select>
  );

  const Shelfs = () => (
    <Form.Select className="mt-1" defaultValue={props.shelf} ref={shelfRef}>
      {whShelf.map((item, index) => (
        <option key={index} value={item}>
          {item}
        </option>
      ))}
    </Form.Select>
  );

  const saveHandler = () => {
    console.log("saveHandler", !!props.inboundSKU);
    // return;
    let quantity = props.quantity;
    if (quantityRef.current?.value !== undefined) quantity = quantityRef.current.value;
    const savedData = {
      row: rowRef.current.value,
      rack: rackRef.current.value,
      shelf: shelfRef.current.value,
      serialNum: serialNumRef.current.value,
      // ALUPONum: vendorRef.current.value,
      // PONum: PONumRef.current.value,
      // SONum: SONumRef.current.value,
      ALUPONum: !!props.inboundSKU ? vendorRef.current?.value : null,
      PONum: !!props.inboundSKU ? PONumRef.current?.value : null,
      SONum: !!props.inboundSKU ? SONumRef.current?.value : null,
      description: descriptionRef.current.value,
      quantity: quantity,
      skunum: props.skunum,
    };
    // console.log("saveHandler", props, savedData);
    // return;
    props.onSaveData(savedData);
    props.onHide();
  };

  // console.log("edit", props);

  return (
    <Modal show={props.showModal} onHide={props.onHide} size="md" centered onShow={modalShown}>
      <Modal.Header closeButton>
        <Modal.Title>Edit SKU {props.skunum}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={Row} className="mb-2">
          <Col>
            Row
            <Rows />
          </Col>
          <Col>
            Rack
            <Racks />
          </Col>
          <Col>
            Shelf
            <Shelfs />
          </Col>
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label>{props.customer === "41" ? "Material ID" : "Serial #"}</Form.Label>
          <Form.Control type="text" defaultValue={props.serialNum} maxLength={32} ref={serialNumRef}></Form.Control>
        </Form.Group>
        {!!props.inboundSKU && (
          <>
            <Form.Group className="mb-2">
              <Form.Label>Vendor</Form.Label>
              <Form.Control type="text" defaultValue={props.vendor} maxLength={32} ref={vendorRef}></Form.Control>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>PO #</Form.Label>
              <Form.Control type="text" defaultValue={props.poNum} maxLength={32} ref={PONumRef}></Form.Control>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>{props.customer === "41" ? "SO # / Bin #" : "SO #"}</Form.Label>
              <Form.Control type="text" defaultValue={props.soNum} maxLength={32} ref={SONumRef}></Form.Control>
            </Form.Group>
          </>
        )}
        <Form.Group className="mb-2">
          <Form.Label>Description</Form.Label>
          <Form.Control as="textarea" rows={2} style={{ resize: "none" }} defaultValue={props.description} placeholder="Description" ref={descriptionRef} disabled={props.role !== 2} />
        </Form.Group>
        {props.role === 2 && (
          <>
            <Form.Label>Quantity</Form.Label>
            <Form.Control type="number" defaultValue={props.quantity} placeholder="0" ref={quantityRef} />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={saveHandler}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditInventory;
